<template>
  <div class="financial-settings-card-wrapper ml-auto mr-auto">
    <v-app>
      <ib-card :animation="false" class="settings-wrapper">
        <v-form>

          <h4 class="settings-header mt-2 text-uppercase">
            {{ $t('pages.financialPlan.settings.currency') }}
          </h4>
          <p class="settings-text mt-1 mb-3">
            {{ $t('pages.financialPlan.settings.currencySubheading') }}
          </p>

          <!-- Currency -->
          <v-autocomplete
            v-model="form.currency"
            :items="currencies"
            item-value="code"
            item-text="name"
            :disabled="!canEdit"
            outlined
            :error="!form.currency"
            :label="$t('pages.financialPlan.settings.currency')"
            height="60px"
            :hide-details="true"
          >
            <template #item="{item}">
              {{ item.name }} ({{ item.symbol }})
            </template>
            <template #selection>
              <span>{{ selectedCurrency.name }} ({{ selectedCurrency.symbol }})</span>
            </template>
          </v-autocomplete>
          <!-- /Currency -->

          <h4 class="settings-header text-uppercase">
            {{ $t('pages.financialPlan.settings.planningStart') }}
          </h4>
          <p class="settings-text mt-1 mb-3">
            {{ $t('pages.financialPlan.settings.planningStartSubheading') }}
          </p>

          <!-- Planning Start -->
          <v-menu
            ref="menu"
            v-model="dateStartingFromMenuVisible"
            :close-on-content-click="false"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDateForStartingFrom"
                :label="$t('pages.financialPlan.settings.planningStart')"
                readonly
                outlined
                :hide-details="true"
                v-bind="attrs"
                :append-icon="icons.mdiCalendarMonthOutline"
                v-on="on"
                @click:append="dateStartingFromMenuVisible = true"
              />
            </template>
            <v-date-picker
              v-model="form.startingFrom"
              color="primary"
              type="month"
              :min="minDate"
              :max="maxDate"
              scrollable
              @change="dateStartingFromMenuVisible = false"
            />
          </v-menu>
          <!-- /Planning Start -->

          <h4 class="settings-header text-uppercase">
            {{ $t('pages.financialPlan.settings.planningPeriod') }}
          </h4>
          <p class="settings-text mt-1 mb-3">
            {{ $t('pages.financialPlan.settings.planningPeriodSubheading') }}
          </p>

          <!-- Planning Period -->
          <v-select
            v-model="form.planningFor"
            :items="options.planningFor"
            item-value="value"
            item-text="label"
            outlined
            label="Planning Period"
            height="60px"
            :menu-props="{offsetY: true}"
            :hide-details="true"
          >
            <template #item="{item}">
              <span>{{ item.label }}</span>
            </template>
            <template #selection>
              <span>{{ selectedPlaningFor.label }}</span>
            </template>
          </v-select>
          <!-- /Planning Period -->
        </v-form>
      </ib-card>

      <!-- Back and Save Buttons-->
      <el-row v-if="canEdit" :gutter="10" class="mt-4">
        <el-col :span="12" :md="{span: 6, offset: 12}">
          <ib-button
            variant="white"
            class="w-100"
            size="lg"
            font-family="regular"
            @click="$router.go(-1)"
          >
            {{ $t('back') }}
          </ib-button>
        </el-col>
        <el-col :span="12" :md="{span: 6}">
          <ib-button
            class="font-weight-bold text-uppercase w-100"
            :loading="loadingOnSaveIdea"
            size="lg"
            font-family="regular"
            @click="onEditIdea"
          >
            {{ $t('save') }}
          </ib-button>
        </el-col>
      </el-row>
    </v-app>
    <!-- /Back and Save Buttons-->
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import MixinCurrencies from '@/mixins/currencies'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import { mapGetters } from 'vuex'
import { mdiCalendarMonthOutline } from '@mdi/js'

import _find from 'lodash/find'

export default {
  name: 'Settings',

  mixins: [MixinCurrencies, MixinIdeaRoles],

  data () {
    return {
      dateStartingFromMenuVisible: false,
      loadingOnSaveIdea: false,
      form: {
        name: '',
        country: '',
        language: 'en',
        currency: 'USD',
        catalogueCategoryId: null,
        startingFrom: this.$moment().format('YYYY-MM'),
        planningFor: ''
      },
      options: {
        planningFor: [
          {
            value: 1,
            label: this.$t('oneYear')
          },
          {
            value: 2,
            label: this.$t('twoYear')
          },
          {
            value: 3,
            label: this.$t('threeYear')
          },
          {
            value: 5,
            label: this.$t('fiveYear')
          }
        ]
      },
      icons: {
        mdiCalendarMonthOutline
      }
    }
  },

  computed: {
    ...mapGetters('idea', ['getIdeaId']),

    selectedCurrency () {
      return _find(this.currencies, ['code', this.form.currency])
    },

    selectedPlaningFor () {
      return _find(this.options.planningFor, ['value', this.form.planningFor])
    },

    formatDateForStartingFrom () {
      return this.$moment(this.form.startingFrom).format('MMMM, yyyy')
    },

    minDate () {
      return this.$moment().startOf('year').subtract(3, 'y').format('YYYY-MM-DD')
    },

    maxDate () {
      return this.$moment().startOf('year').add(4, 'y').format('YYYY-MM-DD')
    }
  },

  mounted () {
    this.setupSettingsData()
  },

  methods: {
    setupSettingsData () {
      const { ideaSettings, name, catalogueCategory } = this.$store.state.idea

      this.form.name = name

      this.form.country = ideaSettings.country
      this.form.planningFor = ideaSettings.planningFor
      this.form.currency = ideaSettings.currency
      this.form.startingFrom = this.$moment(ideaSettings.startingFrom).format('YYYY-MM')
      this.form.catalogueCategoryId = catalogueCategory.id
    },
    onEditIdea () {
      this.loadingOnSaveIdea = true
      this.form.startingFrom = `${this.form.startingFrom}-01`

      this.$http.put(`ideas/${this.getIdeaId}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/setIdea', { idea: response.data.payload.idea })
            this.$store.commit('ideaCollection/updateIdea', response.data.payload.generalInfoIdea)
            this.$emit('idea-saved')
            this.loadingOnSaveIdea = false

            EventBus.$emit('idea-settings-update')
            history.go(-1)
          }
        }).catch(() => {
          this.loadingOnSaveIdea = false
        })
    }
  }
}
</script>

<style scoped lang="scss">

.financial-settings-card-wrapper {
  max-width: 860px;
}
.settings-wrapper {
  padding: 30px 20px;

  @include media-breakpoint-up($md) {
    padding: 30px 40px;
  }
}

.settings-header {
  margin-bottom: 0;
  font-weight: bold;
  font-size: 18px;
}

.settings-text {
  margin-top: 5px;
  font-size: 16px;
  color: rgba(63, 51, 86, 0.9);

  @include media-breakpoint-up($md) {
    font-size: 16px;
  }
}

.bottom-flex {
  margin-top: 1.5rem;
  text-align: right;
}
</style>
